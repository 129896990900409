
import { defineComponent } from "vue";
import {
  COEFFICIENT_MULTIPLY_KHOJA,
  PAYMENT_ODDS,
  TICKET_COMBINATIONS,
  TicketCombinationsItem,
} from "@/constants";
import store from "@/store";
import { formatMoney } from "@/utils";
import { GameId } from "@/types/user";

export default defineComponent({
  name: "winnings-table",
  computed: {
    nominal(): number {
      return store.state.createTicket.amount ?? 10;
    },
    gameId(): GameId | undefined {
      return store.state.currentGameId;
    },
    matrix(): number[][] {
      return PAYMENT_ODDS[this.gameId!];
    },
    newCombinationNumber(): number {
      return store.getters.newCombinationNumber ?? 1;
    },
    isBonusOn(): boolean {
      return store.state.bonus.isOn;
    },
  },
  methods: {
    calculate(cell: number, idx: number) {
      let result = 0;
      if (
        this.isBonusOn &&
        (this.gameId === "loto-khoja" ||
          this.gameId === "loto-silk-way" ||
          this.gameId === "loto-pearl" ||
          this.gameId === "loto-misr") &&
        idx === 0
      ) {
        result = cell * this.nominal * this.newCombinationNumber * COEFFICIENT_MULTIPLY_KHOJA;
      } else if (
        this.isBonusOn &&
        (this.gameId === "loto-khoja" ||
          this.gameId === "loto-silk-way" ||
          this.gameId === "loto-pearl" ||
          this.gameId === "loto-misr") &&
        idx !== 0
      ) {
        result = cell * this.nominal * COEFFICIENT_MULTIPLY_KHOJA;
      } else if (
        (this.gameId === "loto-gambusaki-9" ||
          this.gameId === "loto-gambusaki-gold-9" ||
          this.gameId === "loto-khoja" ||
          this.gameId === "loto-kissaho" ||
          this.gameId === "loto-silk-way" ||
          this.gameId === "loto-pearl" ||
          this.gameId === "loto-misr" ||
          this.gameId === "loto-pamir-legends") &&
        idx === 0
      ) {
        result = cell * this.nominal * this.newCombinationNumber;
      } else {
        result = cell * this.nominal;
      }

      return result > 0 ? formatMoney(result) : "";
    },
  },
});
