import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a142854"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row row-center" }
const _hoisted_4 = {
  key: 0,
  class: "main-container__left"
}
const _hoisted_5 = { class: "main-container__center" }
const _hoisted_6 = { class: "main-container__wrapper" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 1,
  class: "main-container__right"
}
const _hoisted_10 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_CombinationGroup = _resolveComponent("CombinationGroup")!
  const _component_ScoreboardWrapper = _resolveComponent("ScoreboardWrapper")!
  const _component_WinningsTable = _resolveComponent("WinningsTable")!
  const _component_SimpleInfo = _resolveComponent("SimpleInfo")!
  const _component_ModalBonus = _resolveComponent("ModalBonus")!
  const _component_ModalPromo = _resolveComponent("ModalPromo")!
  const _component_ControlPanel = _resolveComponent("ControlPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MainHeader)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.isWidescreen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_CombinationGroup)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ScoreboardWrapper),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.promoIsOn)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["main-container__infoPromo", { '--fruit': _ctx.scoreboardMode === 'fruit' }])
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("promoMode")) + _toDisplayString(" ") + _toDisplayString(_ctx.promoTicketsLeft) + " " + _toDisplayString(" ") + _toDisplayString(_ctx.$t("promoFrom")) + _toDisplayString(" ") + " " + _toDisplayString(_ctx.promoCount) + _toDisplayString(" ") + " " + _toDisplayString(_ctx.$t("promoCount")) + _toDisplayString(" "), 1)
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.messageStatus && !(_ctx.messageStatus === 'won' && _ctx.lastPayout === '0.00'))
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["main-container__info", { '--fruit': _ctx.scoreboardMode === 'fruit' }])
              }, [
                (_ctx.scoreboardMode === 'digit')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t(`information.digit.${_ctx.messageStatus}`, {
                  n: _ctx.stepPayout,
                  c: _ctx.currencyIcon,
                  a: _ctx.currentBonusGameCount,
                  b: _ctx.numBonusGames,
                  y: _ctx.isSamePayout ? _ctx.totalPayoutText : "",
                })), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t(`information.fruit.${_ctx.messageStatus}`, {
                  n: _ctx.stepPayout,
                  c: _ctx.currencyIcon,
                  a: _ctx.currentBonusGameCount,
                  b: _ctx.numBonusGames,
                  y: _ctx.isSamePayout ? _ctx.totalPayoutText : "",
                })), 1))
              ], 2))
            : _createCommentVNode("", true)
        ])
      ]),
      (!_ctx.isWidescreen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_WinningsTable),
            _createVNode(_component_SimpleInfo)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_ModalBonus),
      _createVNode(_component_ModalPromo),
      _createVNode(_component_ControlPanel)
    ])
  ]))
}