
import { defineComponent } from "vue";
import SpinnerLoad from "@/components/Scoreboards/SpinnerLoad.vue";
import store from "@/store";

export default defineComponent({
  name: "gold-cell",
  components: { SpinnerLoad },
  data() {
    return {};
  },
  props: {
    digit: [Number, String],
    size: String,
    animation: {
      type: Boolean,
      default: false,
    },
    glow: {
      type: Boolean,
      default: false,
    },
    opacity: {
      type: Boolean,
      default: false,
    },
    glowForce: {
      type: Boolean,
      default: false,
    },
    idxNumber: {
      type: Number,
      required: true,
    },
    idxString: String,
    loadSpin: Boolean,
    eraserAnimation: Boolean,
  },
  computed: {
    styles(): object {
      return {
        "--glow": (this.animation && this.glow) || this.glowForce,
        "--opacity": (this.animation && !this.glow) || this.opacity,
      };
    },
    eraserCss(): object {
      if (this.size === "small") {
        return {
          "--first": this.digit !== undefined,
        };
      } else {
        return {
          "--first": this.digit !== undefined && [0, 5, 10].includes(this.idxNumber),
          "--second": this.digit !== undefined && [1, 6, 11].includes(this.idxNumber),
          "--third": this.digit !== undefined && [2, 7, 12].includes(this.idxNumber),
          "--fourth": this.digit !== undefined && [3, 8, 13].includes(this.idxNumber),
          "--fifth": this.digit !== undefined && [4, 9, 14].includes(this.idxNumber),
        };
      }
    },
    showCss(): object {
      return {
        "--show": typeof this.digit !== "undefined",
      };
    },
    showFire(): any {
      if (this.size === "small") {
        return {
          "--stepFire": typeof (
            this.fireproof === this.idxNumber &&
            this.fireproof !== this.stepCount &&
            this.fireproof <= this.stepCount - 1 &&
            this.stepFire
          ),
        };
      }
    },
    formatDigit(): number {
      return Number(this.digit) === 10 && !this.isPamir ? 11 : Number(this.digit);
    },
    isPamir(): boolean {
      return (
        store.state.currentGameId === "loto-pamir-legends" ||
        store.state.currentGameId === "loto-pearl" ||
        store.state.currentGameId === "loto-misr"
      );
    },
    isPearl(): boolean {
      return store.state.currentGameId === "loto-pearl";
    },
    isMisr(): boolean {
      return store.state.currentGameId === "loto-misr";
    },
    fireproof(): number | undefined {
      const fireproof = store.state.settings.doubling_game.fireproof_stage;
      return fireproof.enabled ? fireproof.game_number - 1 : undefined;
    },
    stepCount(): number {
      return store.state.doubling.countGame;
    },
    stepFire(): boolean {
      return (
        this.fireproof === this.idxNumber &&
        this.fireproof !== this.stepCount &&
        this.fireproof <= this.stepCount - 1
      );
    },
  },
  methods: {},
});
