
import { defineComponent } from "vue";
import {
  PAYMENT_ODDS,
  TICKET_COMBINATIONS,
  TicketCombinationsItem,
  DESIGNATIONS_MEVAHO_LINKS,
  DESIGNATIONS_GUMBUSAKI_LINKS,
  DESIGNATIONS_KHOJA_LINKS,
  DESIGNATIONS_KISSAHO_LINKS,
  DESIGNATIONS_PEARL_LINKS,
  DESIGNATIONS_SILKWAY_LINKS,
  COEFFICIENT_MULTIPLY_KHOJA,
  DESIGNATIONS_MISR_LINKS,
  DESIGNATIONS_PAMIR_LINKS,
} from "@/constants";
import store from "@/store";
import { formatMoney } from "@/utils";
import { GameId } from "@/types/user";

export default defineComponent({
  name: "mevaho-payments",
  data() {
    return {};
  },
  computed: {
    designationsLinks(): string[] {
      switch (this.gameId) {
        case "loto-gambusaki-9":
        case "loto-gambusaki-gold-9":
          return DESIGNATIONS_GUMBUSAKI_LINKS;
        case "loto-mevaho-5":
          return DESIGNATIONS_MEVAHO_LINKS;
        case "loto-khoja":
          return DESIGNATIONS_KHOJA_LINKS;
        case "loto-kissaho":
          return DESIGNATIONS_KISSAHO_LINKS;
        case "loto-pearl":
          return DESIGNATIONS_PEARL_LINKS;
        case "loto-silk-way":
          return DESIGNATIONS_SILKWAY_LINKS;
        case "loto-misr":
          return DESIGNATIONS_MISR_LINKS;
        case "loto-pamir-legends":
          return DESIGNATIONS_PAMIR_LINKS;
        default:
          return DESIGNATIONS_MEVAHO_LINKS;
      }
    },
    combinationsData(): TicketCombinationsItem {
      return TICKET_COMBINATIONS[store.state.createTicket.combination ?? "combi-1"];
    },
    combinationLine(): number {
      return store.getters.newCombinationNumber ?? 1;
    },
    nominal(): number {
      return store.state.createTicket.amount ?? 10;
    },
    formatNominal(): string {
      return formatMoney(this.nominal!, "fixed");
    },
    gameId(): GameId {
      return store.state.currentGameId;
    },
    matrix(): number[][] {
      return PAYMENT_ODDS[this.gameId];
    },
    isMobile(): boolean {
      return store.state.uiVersion !== "desktop";
    },
    isGambusaki(): boolean {
      return this.gameId === "loto-gambusaki-9" || this.gameId === "loto-gambusaki-gold-9";
    },
    newCombinationNumber(): number {
      return store.getters.newCombinationNumber ?? 1;
    },
    isBonusOn(): boolean {
      return store.state.bonus.isOn;
    },
  },
  methods: {
    calculate(cell: number, idx: number) {
      let result = 0;
      if (
        this.isBonusOn &&
        (this.gameId === "loto-khoja" ||
          this.gameId === "loto-silk-way" ||
          this.gameId === "loto-pearl" ||
          this.gameId === "loto-misr") &&
        idx === 0
      ) {
        result = cell * this.nominal * this.newCombinationNumber * COEFFICIENT_MULTIPLY_KHOJA;
      } else if (
        this.isBonusOn &&
        (this.gameId === "loto-khoja" ||
          this.gameId === "loto-silk-way" ||
          this.gameId === "loto-pearl" ||
          this.gameId === "loto-misr") &&
        idx !== 0
      ) {
        result = cell * this.nominal * COEFFICIENT_MULTIPLY_KHOJA;
      } else if (
        (this.gameId === "loto-gambusaki-9" ||
          this.gameId === "loto-gambusaki-gold-9" ||
          this.gameId === "loto-khoja" ||
          this.gameId === "loto-kissaho" ||
          this.gameId === "loto-silk-way" ||
          this.gameId === "loto-pearl" ||
          this.gameId === "loto-misr" ||
          this.gameId === "loto-pamir-legends") &&
        idx === 0
      ) {
        result = cell * this.nominal * this.newCombinationNumber;
      } else {
        result = cell * this.nominal;
      }

      return result > 0 ? formatMoney(result) : "";
    },
  },
});
