import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c07aad60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-promo__container" }
const _hoisted_2 = { class: "modal-promo__wrapper" }
const _hoisted_3 = { class: "modal-promo__text --color" }
const _hoisted_4 = { class: "modal-promo__text" }
const _hoisted_5 = { class: "modal-promo__text --color" }
const _hoisted_6 = { class: "modal-promo__text --color" }
const _hoisted_7 = { class: "modal-promo__text --color" }
const _hoisted_8 = { class: "modal-promo__wrapper-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileButton = _resolveComponent("MobileButton")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "container",
    onLeave: _ctx.leave,
    duration: "500",
    appear: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-promo", { ['--hidden']: !_ctx.isPromoModal }])
      }, [
        _createVNode(_Transition, {
          name: "slide",
          duration: "500",
          appear: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("modalPromo.congrats")), 1),
                _createElementVNode("p", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.promoQuantity), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.textQuantity) + " " + _toDisplayString(" ") + " " + _toDisplayString(_ctx.$t("modalPromo.bet")) + _toDisplayString(" ") + " ", 1),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.promoAmount), 1),
                  _createTextVNode(" " + _toDisplayString(" ") + " " + _toDisplayString(_ctx.$t("modalPromo.combination")) + _toDisplayString(" ") + " ", 1),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.promoCombination), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_MobileButton, {
                    onClick: _ctx.startTicket,
                    disabled: _ctx.isMaintenance || _ctx.isBetstop || _ctx.disconnected,
                    text: _ctx.$t('modalPromoPlay'),
                    color: _ctx.isGoldButton ? 'gold' : 'yellow',
                    class: "modal-promo__button"
                  }, null, 8, ["onClick", "disabled", "text", "color"]),
                  _createVNode(_component_MobileButton, {
                    onClick: _ctx.hidePromoModal,
                    disabled: _ctx.isMaintenance ||_ctx.isBetstop || _ctx.disconnected,
                    text: _ctx.$t('modalPromoCancel'),
                    color: _ctx.isGoldButton ? 'gold' : 'yellow',
                    class: "modal-promo__button"
                  }, null, 8, ["onClick", "disabled", "text", "color"])
                ])
              ])
            ])
          ]),
          _: 1
        })
      ], 2)
    ]),
    _: 1
  }, 8, ["onLeave"]))
}