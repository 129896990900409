import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49d2f9dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "control-panel"
}
const _hoisted_2 = {
  key: 1,
  class: "control-panel-compact"
}
const _hoisted_3 = {
  key: 0,
  class: "control-panel-mobile__group"
}
const _hoisted_4 = { class: "control-panel-mobile__group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DisplayField = _resolveComponent("DisplayField")!
  const _component_SimpleButton = _resolveComponent("SimpleButton")!
  const _component_IconBrush = _resolveComponent("IconBrush")!
  const _component_AutoGame = _resolveComponent("AutoGame")!
  const _component_ApplyDoubling = _resolveComponent("ApplyDoubling")!
  const _component_CombinationControl = _resolveComponent("CombinationControl")!
  const _component_NominalControl = _resolveComponent("NominalControl")!
  const _component_InformationButton = _resolveComponent("InformationButton")!
  const _component_RefuseDoubling = _resolveComponent("RefuseDoubling")!
  const _component_PlayGame = _resolveComponent("PlayGame")!
  const _component_NominalButton = _resolveComponent("NominalButton")!
  const _component_CombinationButton = _resolveComponent("CombinationButton")!

  return (!_ctx.compact && _ctx.isDesktop)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isSplitAvailable && _ctx.isDoublingOn && !_ctx.isFruitMode)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_DisplayField, {
                center: "",
                value: _ctx.sumAmount,
                title: _ctx.$t('sumAmount')
              }, null, 8, ["value", "title"]),
              _createVNode(_component_DisplayField, {
                "show-zero": "",
                value: _ctx.balance,
                title: _ctx.$t('balance')
              }, null, 8, ["value", "title"]),
              _createVNode(_component_DisplayField, {
                inner: "",
                center: "",
                value: _ctx.sumPaid,
                title: _ctx.$t('winningAmount')
              }, null, 8, ["value", "title"]),
              _createVNode(_component_SimpleButton, {
                onClick: _ctx.split,
                disabled: _ctx.isSplitDisabled || _ctx.isMoreMin || !_ctx.isTakePayout || _ctx.isFireSameStep
              }, {
                default: _withCtx(() => [
                  _createTextVNode("1/2")
                ]),
                _: 1
              }, 8, ["onClick", "disabled"]),
              _createVNode(_component_SimpleButton, {
                onClick: _ctx.resetSplit,
                disabled: _ctx.isResetSplitDisabled || !_ctx.isTakePayout
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconBrush)
                ]),
                _: 1
              }, 8, ["onClick", "disabled"]),
              _createVNode(_component_DisplayField, {
                center: "",
                value: _ctx.currentAmount,
                title: _ctx.$t('amountToDouble')
              }, null, 8, ["value", "title"]),
              _createVNode(_component_DisplayField, {
                center: "",
                value: _ctx.deferredBalance,
                title: _ctx.$t('deferredBalance')
              }, null, 8, ["value", "title"]),
              (_ctx.buttonMode === 'autoGame')
                ? (_openBlock(), _createBlock(_component_AutoGame, {
                    key: 0,
                    disabled: !_ctx.sumAmount || _ctx.isPromoOn
                  }, null, 8, ["disabled"]))
                : _createCommentVNode("", true),
              (_ctx.buttonMode === 'bonus')
                ? (_openBlock(), _createBlock(_component_AutoGame, {
                    key: 1,
                    bonus: "",
                    disabled: !_ctx.sumAmount || _ctx.isPromoOn
                  }, null, 8, ["disabled"]))
                : (_ctx.buttonMode === 'doubling')
                  ? (_openBlock(), _createBlock(_component_ApplyDoubling, {
                      key: 2,
                      onClick: _ctx.applyDoubling
                    }, null, 8, ["onClick"]))
                  : (_ctx.buttonMode === 'autoDoubling')
                    ? (_openBlock(), _createBlock(_component_ApplyDoubling, {
                        key: 3,
                        onClick: _ctx.applyAutoDoubling
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_CombinationControl),
              _createVNode(_component_NominalControl),
              _createVNode(_component_DisplayField, {
                inner: "",
                center: "",
                value: _ctx.sumPaid,
                title: _ctx.$t('winningAmount')
              }, null, 8, ["value", "title"]),
              _createVNode(_component_DisplayField, {
                center: "",
                value: _ctx.sumAmount,
                title: _ctx.$t('sumAmount')
              }, null, 8, ["value", "title"]),
              _createVNode(_component_DisplayField, {
                "show-zero": "",
                value: _ctx.balance,
                title: _ctx.$t('balance')
              }, null, 8, ["value", "title"]),
              (_ctx.buttonMode === 'autoGame')
                ? (_openBlock(), _createBlock(_component_AutoGame, {
                    key: 0,
                    disabled: !_ctx.sumAmount || _ctx.isPromoOn
                  }, null, 8, ["disabled"]))
                : _createCommentVNode("", true),
              (_ctx.buttonMode === 'bonus')
                ? (_openBlock(), _createBlock(_component_AutoGame, {
                    key: 1,
                    bonus: "",
                    disabled: !_ctx.sumAmount || _ctx.isPromoOn
                  }, null, 8, ["disabled"]))
                : (_ctx.buttonMode === 'doubling')
                  ? (_openBlock(), _createBlock(_component_ApplyDoubling, {
                      key: 2,
                      onClick: _ctx.applyDoubling
                    }, null, 8, ["onClick"]))
                  : (_ctx.buttonMode === 'autoDoubling')
                    ? (_openBlock(), _createBlock(_component_ApplyDoubling, {
                        key: 3,
                        onClick: _ctx.applyAutoDoubling
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true)
            ], 64)),
        _createVNode(_component_InformationButton),
        (_ctx.buttonMode === 'doubling')
          ? (_openBlock(), _createBlock(_component_RefuseDoubling, {
              key: 2,
              onClick: _ctx.refuseDoubling
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.buttonMode === 'autoDoubling')
          ? (_openBlock(), _createBlock(_component_RefuseDoubling, {
              key: 3,
              onClick: _ctx.refuseAutoDoubling
            }, null, 8, ["onClick"]))
          : (_ctx.buttonMode === 'autoGame')
            ? (_openBlock(), _createBlock(_component_PlayGame, {
                key: 4,
                disabled: !_ctx.sumAmount
              }, null, 8, ["disabled"]))
            : (_ctx.buttonMode === 'bonus')
              ? (_openBlock(), _createBlock(_component_PlayGame, {
                  key: 5,
                  bonus: "",
                  class: "bonus-button"
                }))
              : _createCommentVNode("", true)
      ]))
    : (_ctx.compact && _ctx.isDesktop)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isSplitAvailable)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_DisplayField, {
                  invert: "",
                  center: "",
                  value: _ctx.sumAmount,
                  title: _ctx.$t('sumAmount')
                }, null, 8, ["value", "title"]),
                _createVNode(_component_DisplayField, {
                  invert: "",
                  "show-zero": "",
                  value: _ctx.balance,
                  title: _ctx.$t('balance')
                }, null, 8, ["value", "title"]),
                _createVNode(_component_DisplayField, {
                  invert: "",
                  inner: "",
                  center: "",
                  value: _ctx.sumPaid,
                  title: _ctx.$t('winningAmount')
                }, null, 8, ["value", "title"]),
                _createVNode(_component_SimpleButton, {
                  onClick: _ctx.split,
                  disabled: _ctx.isSplitDisabled || _ctx.isMoreMin || !_ctx.isTakePayout || _ctx.isFireSameStep
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("1/2")
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"]),
                _createVNode(_component_SimpleButton, {
                  onClick: _ctx.resetSplit,
                  disabled: _ctx.isResetSplitDisabled || !_ctx.isTakePayout
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_IconBrush)
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"]),
                _createVNode(_component_DisplayField, {
                  invert: "",
                  center: "",
                  value: _ctx.currentAmount,
                  title: _ctx.$t('amountToDouble')
                }, null, 8, ["value", "title"]),
                _createVNode(_component_DisplayField, {
                  invert: "",
                  center: "",
                  value: _ctx.deferredBalance,
                  title: _ctx.$t('deferredBalance')
                }, null, 8, ["value", "title"])
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_DisplayField, {
                  invert: "",
                  center: "",
                  value: _ctx.sumAmount,
                  title: _ctx.$t('sumAmount')
                }, null, 8, ["value", "title"]),
                _createVNode(_component_DisplayField, {
                  invert: "",
                  inner: "",
                  center: "",
                  value: _ctx.sumPaid,
                  title: _ctx.$t('winningAmount')
                }, null, 8, ["value", "title"]),
                _createVNode(_component_DisplayField, {
                  invert: "",
                  "show-zero": "",
                  value: _ctx.balance,
                  title: _ctx.$t('balance')
                }, null, 8, ["value", "title"])
              ], 64)),
          _createVNode(_component_InformationButton),
          _createVNode(_component_RefuseDoubling, { onClick: _ctx.refuseDoubling }, null, 8, ["onClick"])
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["control-panel-mobile", { '--radius': _ctx.isModalOn }])
        }, [
          (!(_ctx.isSplitAvailable && _ctx.isDoublingOn))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_NominalButton),
                _createVNode(_component_CombinationButton)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.isSplitAvailable && _ctx.isDoublingOn)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_SimpleButton, {
                    onClick: _ctx.split,
                    disabled: _ctx.isSplitDisabled || _ctx.isMoreMin || !_ctx.isTakePayout || _ctx.isFireSameStep
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("1/2")
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"]),
                  _createVNode(_component_SimpleButton, {
                    onClick: _ctx.resetSplit,
                    disabled: _ctx.isResetSplitDisabled || !_ctx.isTakePayout
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_IconBrush)
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ], 64))
              : _createCommentVNode("", true),
            (!(_ctx.isFruitMode && _ctx.isSplitAvailable && _ctx.isDoublingOn))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.buttonMode === 'autoGame')
                    ? (_openBlock(), _createBlock(_component_AutoGame, {
                        key: 0,
                        disabled: !_ctx.sumAmount || _ctx.isPromoOn
                      }, null, 8, ["disabled"]))
                    : _createCommentVNode("", true),
                  (_ctx.buttonMode === 'bonus')
                    ? (_openBlock(), _createBlock(_component_AutoGame, {
                        key: 1,
                        bonus: "",
                        disabled: !_ctx.sumAmount || _ctx.isPromoOn
                      }, null, 8, ["disabled"]))
                    : (_ctx.buttonMode === 'doubling')
                      ? (_openBlock(), _createBlock(_component_ApplyDoubling, {
                          key: 2,
                          onClick: _ctx.applyDoubling
                        }, null, 8, ["onClick"]))
                      : (_ctx.buttonMode === 'autoDoubling')
                        ? (_openBlock(), _createBlock(_component_ApplyDoubling, {
                            key: 3,
                            onClick: _ctx.applyAutoDoubling
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.buttonMode === 'doubling')
              ? (_openBlock(), _createBlock(_component_RefuseDoubling, {
                  key: 2,
                  onClick: _ctx.refuseDoubling
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.buttonMode === 'autoDoubling')
              ? (_openBlock(), _createBlock(_component_RefuseDoubling, {
                  key: 3,
                  onClick: _ctx.refuseAutoDoubling
                }, null, 8, ["onClick"]))
              : (_ctx.buttonMode === 'autoGame')
                ? (_openBlock(), _createBlock(_component_PlayGame, {
                    key: 4,
                    disabled: !_ctx.sumAmount
                  }, null, 8, ["disabled"]))
                : (_ctx.buttonMode === 'bonus')
                  ? (_openBlock(), _createBlock(_component_PlayGame, {
                      key: 5,
                      bonus: ""
                    }))
                  : _createCommentVNode("", true)
          ])
        ], 2))
}