import { TicketCombination } from "@/types/tickets";
import {
  GAMBUSAKI_TEN,
  GAMBUSAKI_ZEROS_MATRIX,
  PAYMENT_ODDS,
  TICKET_COMBINATIONS,
} from "@/constants";
import { GameId } from "@/types/user";

export const calculateMevahoTicketGlow = (
  numbers: number[],
  combinationType: TicketCombination,
  gameId: GameId
): number[][] => {
  const combination = TICKET_COMBINATIONS[combinationType].matrix as number[][];
  const odds = PAYMENT_ODDS[gameId];
  const res = [];

  for (const [index, line] of combination.entries()) {
    const calc = lineCalc(
      line.map((ind) => numbers[ind]),
      odds,
      index,
      combination,
      "mevaho"
    );
    if (calc.length > 0) res.push(calc);
  }

  // calculate '0'
  let zeros = 0;
  const zerosPos: number[] = [];
  numbers.forEach((v, i) => {
    if (v === 0) {
      zeros++;
      zerosPos.push(i);
    }
  });
  const zi = Math.min(5, zeros);
  if (odds[0][zi - 1]) {
    res.push(zerosPos);
  }
  return res;
};

export const calculateGambusakiTicketGlow = (
  numbers: number[],
  combinationType: TicketCombination,
  gameId: GameId
): number[][] => {
  const combination = TICKET_COMBINATIONS[combinationType].matrix as number[][];
  const odds = PAYMENT_ODDS[gameId];
  const res: number[][] = [];

  // calculate all
  for (const [index, line] of combination.entries()) {
    const calc = lineCalc(
      line.map((ind) => numbers[ind]),
      odds,
      index,
      combination,
      "gambusaki"
    );

    const calcNines = lineCalc(
      line.map((ind) => numbers[ind]),
      odds,
      index,
      combination,
      "nines"
    );

    if (calc.length > 0) res.push(calc);

    if (calcNines.length > 0) res.push(calcNines);
  }

  // calculate '0'
  const zerosPosition: number[] = [];
  for (const [index, line] of GAMBUSAKI_ZEROS_MATRIX.entries()) {
    let hasWon: boolean = false;
    for (let i = 0; i < line.length; i++) {
      if (numbers && numbers[line[i]] === 0) {
        zerosPosition.push(line[i]);
        hasWon = true;
        break;
      }
    }
    if (!hasWon) {
      break;
    }
  }
  const zi = Math.min(5, zerosPosition.length);
  if (odds[0][zi - 1]) {
    res.push(zerosPosition);
  }
  return res;
};

export const calculateKhojaTicketGlow = (
  numbers: number[],
  combinationType: TicketCombination,
  gameId: GameId
): number[][] => {
  const combination = TICKET_COMBINATIONS[combinationType].matrix as number[][];
  const odds = PAYMENT_ODDS[gameId];
  const res: number[][] = [];

  // calculate all
  for (const [index, line] of combination.entries()) {
    const calc = lineCalc(
      line.map((ind) => numbers[ind]),
      odds,
      index,
      combination,
      "khoja"
    );

    const calcTwelve = lineCalc(
      line.map((ind) => numbers[ind]),
      odds,
      index,
      combination,
      "twelve"
    );

    if (calc.length > 0) res.push(calc);

    if (calcTwelve.length > 0) res.push(calcTwelve);
  }

  // calculate '0'
  let zeros = 0;
  const zerosPos: number[] = [];
  numbers.forEach((v, i) => {
    if (v === 0) {
      zeros++;
      zerosPos.push(i);
    }
  });
  const zi = Math.min(5, zeros);
  if (odds[0][zi - 1]) {
    res.push(zerosPos);
  }
  return res;
};

const lineCalc = (
  nums: number[],
  odds: number[][],
  index: number,
  combination: number[][],
  mode:
    | "gambusaki"
    | "mevaho"
    | "khoja"
    | "nines"
    | "twelve"
    | "kissaho"
    | "silk-way"
    | "pearl"
    | "pamir-legends"
): number[] => {
  const res = [];

  const calcLines = () => {
    switch (mode) {
      case "mevaho":
        return dupCalc(nums);
      case "gambusaki":
        return dupCalcGambusaki(nums);
      case "khoja":
      case "kissaho":
      case "silk-way":
      case "pamir-legends":
      case "pearl":
        return dupCalcKhoja(nums);
      case "nines":
        return dupCalcNines(nums);
      case "twelve":
        return dupCalcTwelve(nums);
      default:
        return dupCalc(nums);
    }
  };

  const [value, cnt] = calcLines();

  if (odds[value][cnt]) {
    for (let i = 0; i <= cnt; i++) {
      res.push(combination[index][i]);
    }
  }
  return res;
};

const dupCalc = (data: number[]): [number, number] => {
  let cnt = 0;
  for (let i = 1; i < data.length; i++) {
    if (data[i - 1] === data[i] && data[0] !== 0) {
      cnt++;
    } else {
      break;
    }
  }
  return [data[0], cnt];
};

const dupCalcGambusaki = (data: number[]): [number, number] => {
  let cnt = 0;
  let repNumber: number | undefined = data[0];
  if (repNumber === 0) {
    return [0, 0];
  }
  if (repNumber === 9) {
    repNumber = data.find((el) => el !== 9);
  }
  // все 9ки
  if (!repNumber) {
    return [0, 0];
  }
  // 10ка не участвует в розыгрыше
  if (repNumber === 10) {
    return [0, 0];
  }

  for (let i = 1; i < data.length; i++) {
    if (data[i - 1] === data[i] || data[i] === 9) {
      cnt++;
    } else if (data[i - 1] === 9 && repNumber === data[i]) {
      cnt++;
    } else {
      break;
    }
  }
  return [repNumber, cnt];
};

const dupCalcKhoja = (data: number[]): [number, number] => {
  let cnt = 0;
  let repNumber: number | undefined = data[0];
  if (repNumber === 0) {
    return [0, 0];
  }
  if (repNumber === 12) {
    repNumber = data.find((el) => el !== 12);
  }
  // все 12
  if (!repNumber) {
    return [0, 0];
  }

  for (let i = 1; i < data.length; i++) {
    if (data[i - 1] === data[i] || data[i] === 12) {
      cnt++;
    } else if (data[i - 1] === 12 && repNumber === data[i]) {
      cnt++;
    } else {
      break;
    }
  }
  return [repNumber, cnt];
};

const dupCalcNines = (data: number[]): [number, number] => {
  let cnt = 0;
  if (data[0] !== 9) return [9, 0];
  for (let i = 1; i < data.length; i++) {
    if (data[i] === 9) {
      cnt++;
    } else {
      break;
    }
  }
  return [9, cnt];
};

const dupCalcTwelve = (data: number[]): [number, number] => {
  let cnt = 0;
  if (data[0] !== 12) return [12, 0];
  for (let i = 1; i < data.length; i++) {
    if (data[i] === 12) {
      cnt++;
    } else {
      break;
    }
  }
  return [12, cnt];
};

export const calculateGambusakiTenGlow = (numbers: number[]): number[][] => {
  const res: number[][] = [];
  const tenPosition: number[] = [];
  for (let i = 0; i < GAMBUSAKI_TEN.length; i++) {
    if (numbers && numbers[GAMBUSAKI_TEN[i]] === 10) {
      tenPosition.push(GAMBUSAKI_TEN[i]);
      break;
    }
  }

  if (tenPosition.length > 0) {
    res.push(tenPosition);
  }

  return res;
};

export const calcBonusNumbers = (numbers: number[] | undefined): number[][] => {
  const odds = PAYMENT_ODDS["loto-gambusaki-9"];
  const res: number[][] = [];
  const zerosPosition: number[] = [];
  for (const [index, line] of GAMBUSAKI_ZEROS_MATRIX.entries()) {
    let hasWon: boolean = false;
    for (let i = 0; i < line.length; i++) {
      if (numbers && numbers[line[i]] === 0) {
        zerosPosition.push(line[i]);
        hasWon = true;
        break;
      }
    }
    if (!hasWon) {
      break;
    }
  }
  const zi = Math.min(5, zerosPosition.length);
  if (odds[0][zi - 1]) {
    res.push(zerosPosition);
  }
  return res;
};

export const calcBonusNumbersKhoja = (numbers: number[] | undefined): number[][] => {
  const odds = PAYMENT_ODDS["loto-khoja"];
  const res: number[][] = [];
  let zeros = 0;
  const zerosPos: number[] = [];
  numbers &&
    numbers.forEach((v, i) => {
      if (v === 0) {
        zeros++;
        zerosPos.push(i);
      }
    });
  const zi = Math.min(5, zeros);
  if (odds[0][zi - 1]) {
    res.push(zerosPos);
  }
  return res;
};

export const calcBonusNumbersKissaho = (numbers: number[] | undefined): number[][] => {
  const odds = PAYMENT_ODDS["loto-kissaho"];
  const res: number[][] = [];
  let zeros = 0;
  const zerosPos: number[] = [];
  numbers &&
    numbers.forEach((v, i) => {
      if (v === 0) {
        zeros++;
        zerosPos.push(i);
      }
    });
  const zi = Math.min(5, zeros);
  if (odds[0][zi - 1]) {
    res.push(zerosPos);
  }
  return res;
};

export const calcBonusNumbersPearl = (numbers: number[] | undefined): number[][] => {
  const odds = PAYMENT_ODDS["loto-pearl"];
  const res: number[][] = [];
  let zeros = 0;
  const zerosPos: number[] = [];
  numbers &&
    numbers.forEach((v, i) => {
      if (v === 0) {
        zeros++;
        zerosPos.push(i);
      }
    });
  const zi = Math.min(5, zeros);
  if (odds[0][zi - 1]) {
    res.push(zerosPos);
  }
  return res;
};

export const calcBonusNumbersSilkWay = (numbers: number[] | undefined): number[][] => {
  const odds = PAYMENT_ODDS["loto-silk-way"];
  const res: number[][] = [];
  let zeros = 0;
  const zerosPos: number[] = [];
  numbers &&
    numbers.forEach((v, i) => {
      if (v === 0) {
        zeros++;
        zerosPos.push(i);
      }
    });
  const zi = Math.min(5, zeros);
  if (odds[0][zi - 1]) {
    res.push(zerosPos);
  }
  return res;
};

export const calcBonusNumbersMisr = (numbers: number[] | undefined): number[][] => {
  const odds = PAYMENT_ODDS["loto-misr"];
  const res: number[][] = [];
  let zeros = 0;
  const zerosPos: number[] = [];
  numbers &&
    numbers.forEach((v, i) => {
      if (v === 0) {
        zeros++;
        zerosPos.push(i);
      }
    });
  const zi = Math.min(5, zeros);
  if (odds[0][zi - 1]) {
    res.push(zerosPos);
  }
  return res;
};

export const calcBonusNumbersPamirLegends = (numbers: number[] | undefined): number[][] => {
  const odds = PAYMENT_ODDS["loto-pamir-legends"];
  const res: number[][] = [];
  let zeros = 0;
  const zerosPos: number[] = [];
  numbers &&
    numbers.forEach((v, i) => {
      if (v === 0) {
        zeros++;
        zerosPos.push(i);
      }
    });
  const zi = Math.min(5, zeros);
  if (odds[0][zi - 1]) {
    res.push(zerosPos);
  }
  return res;
};
