import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33779fb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "combination-item__title" }
const _hoisted_2 = { class: "combination-item__count" }
const _hoisted_3 = { class: "combination-item__subTitle" }
const _hoisted_4 = { class: "combination-item__wrapper" }
const _hoisted_5 = { class: "combination-item__text" }
const _hoisted_6 = { class: "combination-item__subTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["combination-item", { '--glow': _ctx.glow }])
  }, [
    _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t("combination")), 1),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.combination.number), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("includeCoords")) + ":", 1),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.combination.array, (item, idx) => {
        return (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item) + _toDisplayString(idx !== _ctx.combination.array.length - 1 ? "," : ""), 1))
      }), 256)),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.orCombinationText), 1)
    ])
  ], 2))
}