
import { defineComponent, PropType } from "vue";
import DigitCell from "@/components/Scoreboards/Digit-cell.vue";
import HighlightingLines from "@/components/Scoreboards/HighlightingLines.vue";
import store from "@/store";
import storeEvents from "@/store-events";
import { UpdateTicket } from "@/types/tickets";
import { AudioUtils } from "@/utils";
import { TYPICAL_ANIMATION_TIME_DIGIT } from "@/constants";
import GoldCell from "@/components/Scoreboards/GoldCell.vue";

export default defineComponent({
  name: "digital-scoreboard",
  components: { GoldCell, HighlightingLines, DigitCell },
  data() {
    return {
      eraserAnimation: false,
      eraserAnimationDoubling: false,
    };
  },
  props: {
    animationIndexes: {
      type: Array as PropType<number[]>,
      default: [],
    },
    scoreboard: {
      type: Array,
      default: new Array(15),
    },
    isWon: Boolean,
  },
  watch: {
    // scoreboard() {
    //   if (Object.keys(this.scoreboard).length) {
    //     AudioUtils.stopSound("receipt-erase");
    //     AudioUtils.playSound("receipt-erase", 0, TYPICAL_ANIMATION_TIME_DIGIT);
    //   }
    // },
  },
  mounted() {},
  beforeMount() {
    storeEvents.on("afterTicketUpdate", this.afterTicketUpdate);
    storeEvents.on("isPlayDoublingWon", this.afterPlayDoubling);
  },
  beforeUnmount() {
    storeEvents.off("afterTicketUpdate", this.afterTicketUpdate);
    storeEvents.off("isPlayDoublingWon", this.afterPlayDoubling);
  },
  computed: {
    digitWinnings(): string[] {
      return store.state.doubling.digitWinnings;
    },
    doublingCount(): number {
      return store.state.doubling.countGame;
    },
    isDoublingAnim(): boolean {
      // if (store.state.doubling.isDigitDoublingAnim) {
      //   AudioUtils.stopSound("receipt-breakdown-winning");
      //   AudioUtils.playSound("receipt-breakdown-winning", 0, 1);
      // }
      return store.state.doubling.isDigitDoublingAnim;
    },
    hasDoublingWinnings(): boolean {
      return !!store.state.doubling.digitWinnings[0];
    },
    isGambusakiGold(): boolean {
      return (
        store.state.currentGameId === "loto-gambusaki-gold-9" ||
        store.state.currentGameId === "loto-pearl" ||
        store.state.currentGameId === "loto-misr"
      );
    },
    isPamirLegends(): boolean {
      return store.state.currentGameId === "loto-pamir-legends";
    },
    gamesGoldCell(): boolean {
      return (
        store.state.currentGameId === "loto-pamir-legends" ||
        store.state.currentGameId === "loto-gambusaki-gold-9" ||
        store.state.currentGameId === "loto-pearl" ||
        store.state.currentGameId === "loto-misr"
      );
    },
  },
  methods: {
    isGlow(idx: number): boolean {
      return this.animationIndexes.includes(idx);
    },
    idxString(idx: number): string {
      switch (idx) {
        case 0:
          return "A";
        case 5:
          return "B";
        case 10:
          return "C";
        default:
          return "";
      }
    },
    afterTicketUpdate(ticket: UpdateTicket) {
      this.eraserAnimation = true;
    },
    afterPlayDoubling() {
      this.eraserAnimationDoubling = true;
    },
  },
});
