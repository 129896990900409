import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3028751d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "digital-scoreboard" }
const _hoisted_2 = { class: "digital-scoreboard__top" }
const _hoisted_3 = { class: "digital-scoreboard__bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoldCell = _resolveComponent("GoldCell")!
  const _component_DigitCell = _resolveComponent("DigitCell")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scoreboard, (data, idx) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (_ctx.gamesGoldCell)
            ? (_openBlock(), _createBlock(_component_GoldCell, {
                key: 0,
                digit: data,
                animation: _ctx.isWon,
                glow: _ctx.isGlow(idx),
                idxNumber: idx,
                idxString: _ctx.idxString(idx),
                eraserAnimation: _ctx.eraserAnimation
              }, null, 8, ["digit", "animation", "glow", "idxNumber", "idxString", "eraserAnimation"]))
            : (_openBlock(), _createBlock(_component_DigitCell, {
                key: 1,
                digit: data,
                animation: _ctx.isWon,
                glow: _ctx.isGlow(idx),
                idxNumber: idx,
                idxString: _ctx.idxString(idx),
                eraserAnimation: _ctx.eraserAnimation
              }, null, 8, ["digit", "animation", "glow", "idxNumber", "idxString", "eraserAnimation"]))
        ], 64))
      }), 256))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.digitWinnings, (value, idx) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (_ctx.gamesGoldCell)
            ? (_openBlock(), _createBlock(_component_GoldCell, {
                key: 0,
                idxNumber: idx,
                idxString: idx === 0 ? 'D' : '',
                size: "small",
                digit: value,
                opacity: _ctx.doublingCount < idx && _ctx.isDoublingAnim,
                loadSpin: _ctx.doublingCount === idx && _ctx.isDoublingAnim,
                glowForce: _ctx.doublingCount - 1 === idx && _ctx.hasDoublingWinnings && _ctx.isDoublingAnim,
                eraserAnimation: _ctx.eraserAnimationDoubling && _ctx.doublingCount - 1 === idx
              }, null, 8, ["idxNumber", "idxString", "digit", "opacity", "loadSpin", "glowForce", "eraserAnimation"]))
            : (_openBlock(), _createBlock(_component_DigitCell, {
                key: 1,
                idxNumber: idx,
                idxString: idx === 0 ? 'D' : '',
                size: "small",
                digit: value,
                opacity: _ctx.doublingCount < idx && _ctx.isDoublingAnim,
                loadSpin: _ctx.doublingCount === idx && _ctx.isDoublingAnim,
                glowForce: _ctx.doublingCount - 1 === idx && _ctx.hasDoublingWinnings && _ctx.isDoublingAnim,
                eraserAnimation: _ctx.eraserAnimationDoubling && _ctx.doublingCount - 1 === idx
              }, null, 8, ["idxNumber", "idxString", "digit", "opacity", "loadSpin", "glowForce", "eraserAnimation"]))
        ], 64))
      }), 256))
    ])
  ]))
}