import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65ba2eef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "simple-info__container" }
const _hoisted_2 = { class: "simple-info__draw" }
const _hoisted_3 = {
  key: 0,
  class: "simple-info__wrapper"
}
const _hoisted_4 = { class: "simple-info__text" }
const _hoisted_5 = { class: "simple-info__text" }
const _hoisted_6 = { class: "simple-info__text" }
const _hoisted_7 = { class: "simple-info__text" }
const _hoisted_8 = { class: "simple-info__text" }
const _hoisted_9 = { class: "simple-info__text" }
const _hoisted_10 = { class: "simple-info__text simple-info__gmt" }
const _hoisted_11 = {
  key: 1,
  class: "simple-info__wrapper"
}
const _hoisted_12 = {
  key: 0,
  class: "simple-info__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["simple-info", { ['--opened']: _ctx.opened && _ctx.isDesktop }])
  }, [
    (_ctx.isDesktop)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "simple-info__icon",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.switchMode && _ctx.switchMode(...args)))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: _normalizeClass(["simple-info__loto", { ['--opacity']: !(_ctx.ticket && _ctx.ticket.ticket_id) }])
      }, _toDisplayString(_ctx.$t("lotoInfo")) + " " + _toDisplayString(_ctx.ticket && _ctx.ticket.ticket_id), 3),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("draw")) + " № " + _toDisplayString(_ctx.ticket && _ctx.ticket.draw_id), 1),
      (_ctx.ticket && _ctx.ticket.ticket_id && _ctx.isDesktop)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("lotoInfoDescription1")), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("lotoInfoDescription2")), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("lotoInfoDescription3")), 1),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("lotoInfoDescription4")) + " " + _toDisplayString(_ctx.sumAmount / 100), 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("combination")) + " " + _toDisplayString(_ctx.combinationLines), 1),
            _createElementVNode("span", _hoisted_9, [
              _createTextVNode(_toDisplayString(_ctx.$t("lotoInfoDate")) + " " + _toDisplayString(_ctx.received) + " ", 1),
              _createElementVNode("span", _hoisted_10, "GMT +" + _toDisplayString(_ctx.gmt), 1)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, [
            (_ctx.isDesktop)
              ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t("lotoInfoNoData")), 1))
              : _createCommentVNode("", true)
          ]))
    ])
  ], 2))
}